@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.card {
  width: 280px;
  height: 338px;
  position: relative;
  padding: 0;
}

.footer {
  width: 100%;
  height: 63px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var.$color-neutral-0;
  opacity: 0.8;
  padding: 0 fn.spacing(2);
  text-align: center;
}

.action {
  height: 100%;
  width: 100%;
}
