@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.icon-list-item {
  list-style: none;

  svg {
    stroke: var.$color-blue-300;
  }

  path {
    stroke: var.$color-blue-300;
  }
}

.icon-item {
  display: flex;
  text-align: left;
  flex-flow: row nowrap;
  margin-bottom: fn.spacing(1.5);
}

.icon-item-svg {
  margin-right: fn.spacing(1.5);
  margin-top: fn.spacing(0.5);
}

.icon-item p {
  display: contents;
}

.icon-item[data-no-margin-bottom='true'] {
  margin-bottom: fn.spacing(0);
}

.no-margin-bottom {
  margin-bottom: fn.spacing(0);
}
