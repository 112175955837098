@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.outer-wrapper-widget {
  display: contents;

  .container {
    border-radius: var.$achieve-default-border-radius;
    background-color: var.$color-blue-50;
  }

  .image {
    border-radius: var.$achieve-default-border-radius;
    height: 264px;
    width: 264px;
  }

  .cta-tile {
    padding: fn.spacing(3) 0;
  }

  .cta-subtitle {
    margin-top: fn.spacing(2);
  }

  .cta-button {
    margin-top: fn.spacing(2);
    margin-bottom: fn.spacing(4);
  }

  .image-container {
    padding: fn.spacing(2);
    border-radius: var.$achieve-default-border-radius;
  }

  .inner-container {
    margin-top: fn.spacing(1);
  }

  .text-container {
    padding: fn.spacing(3);
  }
}

.outer-wrapper-main {
  display: contents;

  .container {
    margin: fn.spacing(8) 0;
    padding: fn.spacing(4);
    background-color: var.$color-blue-50;
    border-radius: var.$achieve-default-border-radius;
  }

  .text-container {
    flex-direction: column;
    text-align: center;
  }

  .cta-tile {
    padding: fn.spacing(3) 0;
    margin-bottom: fn.spacing(4);
  }

  .cta-subtitle {
    margin-top: fn.spacing(2);
  }

  .cta-button {
    margin-top: fn.spacing(4);
  }
}
