@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container-grid {
  color: var.$color-neutral-300;
  background-color: var.$color-blue-50;
  padding: fn.spacing(4) fn.spacing(1.5) fn.spacing(2) fn.spacing(1.5);
  border-radius: var.$achieve-default-border-radius;
  margin-bottom: fn.spacing(4);
}

@media screen and (min-width: var.$breakpoint-lg) {
  .container-grid {
    padding: fn.spacing(4) fn.spacing(11) fn.spacing(4) fn.spacing(11);
  }

  .text {
    margin: fn.spacing(2) 0;
  }
}
