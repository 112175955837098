@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container {
  padding: fn.spacing(6) 0;
  display: flex;
  justify-content: center;
}

.card {
  max-width: 420px;
  position: relative;
  padding: fn.spacing(3);
  display: flex;
  flex-direction: row;
  gap: fn.spacing(1.5);
}

.card::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: fn.spacing(1);
  padding: 2px;
  background: linear-gradient(180deg, #3b6af7 0%, #1ab1e2 100%);
  mask: initial;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: xor;
  mask-composite: exclude;
}

.contianer-img {
  z-index: 1;
}

.badge-img {
  padding: fn.spacing(1);
  margin-top: fn.spacing(-8);
  border-radius: 20%;
  background: var.$color-background-primary;
  z-index: 1;
}

.button-link span a {
  width: 100%;
}
