@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.card {
  background-color: var.$color-blue-50;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  max-width: 350px;
  border-radius: 0;
  padding: fn.spacing(2) 0;
}

.image {
  border-radius: var.$achieve-default-border-radius;
  width: 310px;
}

.title-typography {
  padding: 0 fn.spacing(4);
  margin: fn.spacing(2) 0;
  text-align: center;
}

.text-typography {
  padding: 0 fn.spacing(4);
  margin: fn.spacing(2) 0;
  text-align: center;
}
