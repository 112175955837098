@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.typography-table {
  margin-bottom: fn.spacing(4);
  height: auto;
}

.typography-cell {
  height: auto;
}

.typography-ol {
  margin-bottom: fn.spacing(4);
  list-style-type: decimal;
}

.typography-ul {
  margin-bottom: fn.spacing(4);
}

.typography-li {
  margin-bottom: fn.spacing(0.5);
}

.typography-hr {
  margin-bottom: fn.spacing(4);
}

.typography-li p:last-child {
  margin-bottom: 0;
}

.typography-table td p:last-child {
  margin-bottom: 0;
}

.typography-table th p:last-child {
  margin-bottom: 0;
}

.typography-ol > li > ol {
  list-style-type: upper-alpha;
}

.typography-ol > li > ol > li > ol {
  list-style-type: lower-roman;
}

.typography-ol > li > ol > li > ol > li > ol {
  list-style-type: lower-alpha;
}

.typography-ol li p:only-of-type,
.typography-ol li .typography-ol,
.typography-ul li .typography-ul,
.typography-ul li .typography-ol,
.typography-ol li .typography-ul {
  margin-bottom: 0;
}

.img-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.responsive-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: var.$breakpoint-md) {
  .typography-table-full table {
    display: block;
  }

  .typography-table-full td,
  .typography-table-full th {
    width: 100%;
  }
}
