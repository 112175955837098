@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.typography-paragraph {
  margin-bottom: fn.spacing(2.5);
  scroll-margin-top: fn.spacing(9);
  line-height: 170%;
  font-weight: 400;
}

.typography-heading-1 {
  margin-bottom: fn.spacing(2.5);
  scroll-margin-top: fn.spacing(9);
}

.typography-heading-2 {
  margin-bottom: fn.spacing(2.5);
  margin-top: fn.spacing(5);
  scroll-margin-top: fn.spacing(9);
}

.typography-heading-3 {
  margin-bottom: fn.spacing(2.5);
  margin-top: fn.spacing(5);
  scroll-margin-top: fn.spacing(9);
}

.typography-heading-4 {
  margin-bottom: fn.spacing(2.5);
  margin-top: fn.spacing(5);
  scroll-margin-top: fn.spacing(9);
  line-height: 170%;
}

.typography-heading-5 {
  margin-bottom: fn.spacing(2.5);
  scroll-margin-top: fn.spacing(9);
}

.typography-heading-6 {
  margin-bottom: fn.spacing(2.5);
  scroll-margin-top: fn.spacing(9);
}

.typography-ol {
  margin-bottom: fn.spacing(2.5);
  scroll-margin-top: fn.spacing(9);
  list-style-type: decimal;
}

.typography-ul {
  margin-bottom: fn.spacing(2.5);
  scroll-margin-top: fn.spacing(9);
  padding-left: fn.spacing(2.5);
}

.typography-ul .typography-ul {
  margin-top: fn.spacing(-2);
}

.typography-li {
  margin-bottom: fn.spacing(0.5);
  scroll-margin-top: fn.spacing(9);
}

.typography-hr {
  margin-bottom: fn.spacing(2.5);
  scroll-margin-top: fn.spacing(9);
}

.typography-table {
  margin-bottom: fn.spacing(2.5);
  height: auto;
  scroll-margin-top: fn.spacing(9);

  > :first-child {
    overflow: auto;
  }
}

.typography-cell {
  height: auto;
}

.typography-hyperlink {
  padding: 0;
  scroll-margin-top: fn.spacing(9);
}

.typography-li p:last-child {
  margin-bottom: 0;
}

.typography-table td p:last-child {
  margin-bottom: 0;
}

.typography-table th p:last-child {
  margin-bottom: 0;
}

.typography-default {
  scroll-margin-top: fn.spacing(9);
}

.video-player {
  width: 100%;
  height: auto;
}

.typography-ol > li > ol {
  list-style-type: upper-alpha;
}

.typography-ol > li > ol > li > ol {
  list-style-type: lower-roman;
}

.typography-ol > li > ol > li > ol > li > ol {
  list-style-type: lower-alpha;
}

.typography-ol li p:only-of-type,
.typography-ol li .typography-ol,
.typography-ul li .typography-ul,
.typography-ul li .typography-ol,
.typography-ol li .typography-ul {
  margin-bottom: 0;
}

.img-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.responsive-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: var.$breakpoint-md) {
  .typography-paragraph {
    font-size: 14px;
  }

  .typography-table-full table {
    display: block;
  }

  .typography-table-full td,
  .typography-table-full th {
    width: 100%;
  }
}
