@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.base-styles {
  &[data-variant='text'] {
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }

  &.base-styles[data-variant='contained'] {
    &:hover {
      color: var.$color-blue-500;
      background-color: var.$color-blue-200;
    }
  }
}
