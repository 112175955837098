@use 'styles/functions' as fn;
@use 'styles/variables' as var;

.skeleton-container {
  display: flex;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.iframe-wrapper {
  display: flex;
  width: 100vw;
  justify-content: center;
  height: fit-content;
  margin-bottom: fn.spacing(2);
}

.iframe-wrapper-external {
  width: 100%;
  height: fit-content;
}

.video-player-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: fit-content;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.play-button {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
  width: 64px;
  height: 64px;
}

.thumbnail-container {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .play-button {
    width: 80px;
    height: 80px;
  }
}
