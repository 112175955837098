@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container {
  border-top: 0.75px solid var.$color-neutral-150;
  border-bottom: 0.75px solid var.$color-neutral-150;
  padding: fn.spacing(2) 0;
  margin-top: fn.spacing(4);
}

.title-eyebrow {
  text-align: left;
  padding-bottom: 0;
  color: var.$color-content-secondary;
}

.image {
  border-radius: fn.spacing(1);
}

.content-text {
  color: var.$color-neutral-600;
}

.container-img {
  padding: 0;
  margin-bottom: fn.spacing(2);
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .container-img {
    padding: fn.spacing(2);
    margin-bottom: 0;
  }
}
